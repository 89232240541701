import { post } from '@/application/api/implementations/app';

export default function (email, password) {

  return post('auth/login', {
    email,
    password,
    'client': process.env.VUE_APP_CLIENT_ENVIRONMENT,
  });
}
