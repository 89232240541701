<template>
  <v-col class="pa-3">
    <v-form ref="form" v-model="isValid" @submit.prevent="handleLogin" class="pt-10">
      <h1 class="text-h1 mt-10">
        {{ $t('authorisation.login.title').toUpperCase() }}
      </h1>
      <v-alert :value="!!errorMessage.length" class="my-5" transition="fade-transition" type="error">
        {{ errorMessage }}
      </v-alert>
      <k-field-group language-prefix="authorisation.fields" class="mt-4 mb-2">
        <KTextField v-model="form.email" autocomplete="username" autofocus field="email" tabindex="1"/>
        <KTextField v-model="form.password"
                    :append-icon="showPassword ? '$invisible' : '$visible'"
                    :type="showPassword ? 'text' : 'password'"
                    autocomplete="current-password"
                    field="password"
                    tabindex="2"
                    @click:append="showPassword = !showPassword"/>
      </k-field-group>
      <v-row class="mx-0 my-6" justify="end">
        <VBtn :to="{name: 'password.forgotten'}"
              tabindex="4"
              text tile
              v-text="$t('authorisation.actions.passwordForgotten')"
              class="mr-3"/>
        <VBtn :loading="isLoading"
              tabindex="3"
              type="submit"
              tile
              depressed
              color="secondary"
              v-text="$t('authorisation.actions.login')"/>
      </v-row>
    </v-form>
    <v-overlay v-model="isRedirecting" class="text-center">
      <VProgressCircular indeterminate size="64"/>
      <div class="mt-5" v-text="$t('authorisation.login.successMessage')"></div>
    </v-overlay>
  </v-col>
</template>

<script lang="js">
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import { mapGetters } from 'vuex';
import LoginRequest from '@/modules/authorisation/api/login.js';
import { getRateLimitMinutes } from '@/application/api/util/response.js';

export default {
  name: 'Login',
  components: {
    KFieldGroup,
    KTextField,
  },
  data() {
    return {
      errorMessage: '',
      isLoading: false,
      showPassword: false,
      isValid: false,
      form: {
        email: '',
        password: '',
      },
      isRedirecting: false,
    };
  },
  computed: {
    ...mapGetters({
      findError: 'error/find',
    }),
  },
  methods: {
    handleLogin() {
      this.isLoading = true;
      LoginRequest(this.form.email, this.form.password)
          .then((res) => {
            this.isRedirecting = true;
            this.redirectToAuthDispense(res.data.data.token);
          })
          .catch((err) => {
            this.errorMessage = this.$t('errors.defaultError');

            const { response } = err;
            const { status } = response;

            if (status === 429) {
              this.errorMessage = this.$t('errors.429', { minutes: getRateLimitMinutes(response) });
            } else {
              this.errorMessage = this.findError('email');
            }
            this.$refs.form.validate();
          })
          .finally(() => {
            this.isRedirecting = false;
            this.isLoading = false;
          });
    },
    redirectToAuthDispense(token) {
      const form = document.createElement('form');

      form.method = 'POST';
      form.action = `${process.env.VUE_APP_ROOT_API}/auth/dispense`;

      const redirectUriElement = document.createElement('input');
      redirectUriElement.name = 'redirect_uri';
      redirectUriElement.value = 'home';
      form.appendChild(redirectUriElement);

      const emailElement = document.createElement('input');
      emailElement.name = 'email';
      emailElement.value = this.form.email;
      form.appendChild(emailElement);

      const tokenElement = document.createElement('input');
      tokenElement.name = 'token';
      tokenElement.value = token;
      form.appendChild(tokenElement);

      const clientElement = document.createElement('input');
      clientElement.name = 'client';
      clientElement.value = process.env.VUE_APP_CLIENT_ENVIRONMENT;
      form.appendChild(clientElement);

      document.body.appendChild(form);
      form.submit();
    },
  },
};
</script>
